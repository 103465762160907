import type { FetchMoreOptions, OperationVariables } from '@apollo/client'
import type { UseLazyQueryReturn, UseQueryReturn } from '@vue/apollo-composable'
import type { UnwrapRef } from 'vue'

export type InferredUseQueryReturn<T> =
  | UseLazyQueryReturn<InferredResult<T>, InferredVariables<T>>
  | UseQueryReturn<InferredResult<T>, InferredVariables<T>>

type GetNumberOf<T, N> = (
  result: UnwrapRef<InferredUseQueryReturn<T>['result']>,
) => N | undefined
type InferredResult<T> = T extends UseQueryReturn<infer U, any> ? U : any
type InferredVariables<T> = T extends UseQueryReturn<any, infer C> ? C : any

export const useFetchMoreWrapper = <
  T extends InferredUseQueryReturn<T>,
  N extends number | Record<string, number>,
>(
  query: () => T,
  updateQuery: FetchMoreOptions['updateQuery'],
  getCount: GetNumberOf<T, N>,
  getTotal: GetNumberOf<T, N>,
  errorPrefix: string,
) => {
  const { addToastError } = useToaster()

  const payload = query()

  const count = computed(() => getCount(payload.result.value) ?? 0)
  const total = computed(() => getTotal(payload.result.value) ?? 0)

  const handleError = (error: unknown) => {
    addToastError({
      error,
      prefix: errorPrefix,
      tracking: {
        topicKey: 'load_more_failed',
      },
    })
  }

  const invokeFetchMore = async (variables: OperationVariables = {}) => {
    if (total.value === 0) return

    try {
      await payload.fetchMore({
        updateQuery,
        variables: variables as any,
      })
    } catch (error) {
      handleError(error)
    }
  }

  const invokeRefetch = async (variables: OperationVariables = {}) => {
    try {
      await payload.fetchMore({
        updateQuery,
        variables: variables as any,
      })
    } catch (error) {
      handleError(error)
    }
  }

  payload.onError(handleError)

  return {
    ...payload,
    count,
    fetchMore: invokeFetchMore,
    refetch: invokeRefetch,
    total,
  }
}
